<template>
<div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submitUser)" autocomplete="off">
            <h5 class="mt-4 text-secondary">Driver's Licence</h5>
            <vs-divider />

            <div class="vx-row mb-2">
                <div class="vx-col sm:w-1/3 w-full">
                    <span class="text-right">Licence Number:</span>
                </div>

                <div class="vx-col sm:w-1/3 w-full">
                    <ValidationProvider class="w-full" name="number" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 15 }" v-slot="{ errors }" :custom-messages="customMessages">
                        <vs-input class="w-full" name="number" v-model="profileDocument.documentNumber" />
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>

            <div class="vx-row mb-2">
                <div class="vx-col sm:w-1/3 w-full">
                    <span class="text-right">State:</span>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <ValidationProvider class="w-full" :rules="{ required: true, max: 100 }" v-slot="{ errors }" :custom-messages="customMessages">
                        <vs-select name="state" v-model="profileDocument.state" class="w-full">
                            <vs-select-item :key="index" :value="item.code" :text="item.name" v-for="(item, index) in stateList" />
                        </vs-select>
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>

            <div class="vx-row mb-base">
                <div class="vx-col sm:w-1/3 w-full">
                    <span class="text-right w-full">Expiry Date:</span>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <ValidationProvider class="w-full" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages" name="ExpiryDate">
                        <ejs-maskedtextbox class="w-full" ref="ExpiryDate" name="ExpiryDate" mask="00/00/0000" v-model="profileDocument.expiryDateString" floatLabelType='Always'>
                        </ejs-maskedtextbox>
                        <span>dd/mm/yyyy e.g. 25/07/2022</span>
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>

            <div class="vx-row mb-base">
                <div class="vx-col sm:w-1/3 w-full">
                    <span class="text-right"></span>
                </div>
                <div class="vx-col sm:w-1/2 w-full">
                    <vs-table stripe :data="profileDocumentList">
                        <template :slot-scope="{ data }">
                            <vs-tr :key="i" v-for="(tr, i) in profileDocumentList" :data="tr">
                                <vs-td :class="{ 'line-through': tr.isDeleted }">
                                    <span @click="handleViewSidebar(tr.id)">
                                        {{ tr.originalFileName }}
                                    </span>
                                </vs-td>
                                <vs-td class="width1">
                                    <vs-icon :href="tr.originalFileName" @click="deleteFile(tr.id)" icon="icon-trash" icon-pack="feather" size="medium" class="hover:text-primary ml-5"></vs-icon>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
            </div>

            <h5 class="mt-4 text-secondary">Upload license</h5>
            <vs-divider />

            <div class="vx-row mb-2">
                <div class="vx-col sm:w-1/3 w-full">
                    <span class="text-right"></span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vue2Dropzone v-if="isUpdated" :key="componentKey" class="w-full border-dotted mb-base" ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue2Dropzone>
                </div>
            </div>
            <vs-divider />
            <div class="vx-row mb-2">
                <div class="vx-col sm:w-1/3 w-full">
                    <span class="text-right"></span>
                </div>
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-button @click="submitUser">Submit</vs-button>
                </div>
            </div>
        </form>
    </ValidationObserver>
    <vx-previewfile v-if="previewFileActive" :openlocationwindow="previewFileActive" :previewFileData="previewFileData" @closeWindow="closeWindow" />
</div>
</template>

<script>
import {
    customMessages
} from "./../../filters/validationmessage";
import {
    treeMixData
} from "../../store/api/treeMix";
import {
    profileData
} from "../../store/api/profile";
import GeoSuggest from "../../components/GeoSuggest/GeoSuggest";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import {
    BaseConfig
} from "../../store/api/config";
import AuthService from "../../services/AuthService";
import {
    FileConfig
} from "../../store/fileConfig.js";
const authservice = new AuthService();

export default {
    props: {
        currentEmployeId: {
            type: String,
        },
        salutation: {
            type: Array,
        }
    },
    data() {
        return {
            componentKey: 1,
            customMessages,
            profileDocument: {},
            profileDocumentFile: {},
            profileDocumentList: [],
            stateList: [],
            isUpdated: false,
            selectedEmployeeId: {
                ...this.currentEmployeId
            },
            fileUploadAction: "",
            dropzoneOptions: {
                url: `${BaseConfig.BHAPI}api/IdentityDocument/UploadDrivingLicenseDocuments`,
                maxFilesize: 2560,
                thumbnailWidth: 300,
                thumbnailHeight: 300,
                autoQueue: false,
                autoProcessQueue: false,
                uploadMultiple: true,
                maxFiles: 2,
                init: function () {
                    this.on("maxfilesexceeded", function (file) {
                        this.removeAllFiles();
                        this.addFile(file);
                    });
                },
                addRemoveLinks: true,
                dictDefaultMessage: 'Please upload front side of your driving licence. You can choose multiple files to upload in one go.',
            },
            imageExt: FileConfig.ImageExt,
            previewFileData: undefined,
            previewFileActive: false,
        };
    },
    components: {
        GeoSuggest,
        flatPickr,
        vue2Dropzone,
    },
    watch: {
        currentEmployeId: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.selectedEmployeeId = newValue;
            }
        }
    },
    async created() {
        let token = await authservice.getSilentToken("bh");
        let myHeaders = {
            Authorization: "Bearer " + token
        };
        this.headers = myHeaders;
        this.fileUploadAction = `${BaseConfig.BHAPI}api/IdentityDocument/UploadDrivingLicenseDocuments`;
        await this.loadFileConfig();
        this.stateList = treeMixData.searchListOfStates();
        await this.loadDocument();
    },
    methods: {
        async loadDocument() {
            this.profileDocument = await profileData.SearchIdentityDocument();
            if (!this.profileDocument) {
                this.profileDocument = {};
                this.profileDocumentFile = {};
                this.profileDocumentList = [];
            } else {
                this.profileDocumentList = this.profileDocument.files;
                this.profileDocumentFile = this.profileDocument.files[0];
            }
            this.previewFileActive = false;
            this.componentKey + 1;
            this.$refs.myVueDropzone.removeAllFiles();
        },
        async loadFileConfig() {
            this.dropzoneOptions = {
                ...this.dropzoneOptions,
                url: this.fileUploadAction,
                headers: this.headers,
                includeStyling: true,
                duplicateCheck: true,
                autodiscover: false,
                autoQueue: false,
                autoProcessQueue: false,
                acceptedFiles: ".png,.jpg,.pdf,.jpeg",
            }
            this.isUpdated = true
        },
        async submitUser() {
            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            var formData = new FormData();
            formData.append("documentNumber", this.profileDocument.documentNumber);
            formData.append("expiryDate", this.$refs.ExpiryDate.getMaskedValue());
            formData.append("state", this.profileDocument.state);

            if (this.$refs.myVueDropzone.dropzone.files.length > 0) {
                var files = this.$refs.myVueDropzone.dropzone.files;
                files.forEach((element) => formData.append("fileInput", element));
            }

            const result = await profileData.UploadIdentityDocument(formData);
            if (!result.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: result.message,
                    color: "danger",
                    position: "top-center",
                });
                return;
            }

            this.$vs.notify({
                title: "Success",
                text: "Record Saved",
                color: "success",
                position: "top-center",
            });
            this.loadDocument()
        },
        async handleViewSidebar(id) {
            let file = this.profileDocumentList.find(x => x.id == id);
            this.previewFileData = {
                fileURL: file.downloadUrl,
                fileFormat: file.fileExtension
            }
            this.previewFileActive = true;
        },
        async closeWindow() {
            this.previewFileActive = false;
        },
        async deleteFile(id) {
            if (confirm("are you sure ? ")) {
                var result = await profileData.RemoveLibraryDocument(id);
                if (!result.succeeded) {
                    this.$vs.notify({
                        title: "Error",
                        text: result.message,
                        color: "danger",
                        position: "top-center",
                    });
                    return;
                }

                this.$vs.notify({
                    title: "Success",
                    text: "Record Deleted",
                    color: "success",
                    position: "top-center",
                });
                await this.loadDocument();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.text-right {
    float: right;
    margin-top: 5px;

    @media screen and (max-width: 1200px) {
        float: left;
    }
}
</style>
